<template>
	<b-navbar variant="primary">
		<b-navbar-brand to="/">
			<b-img :src="appLogo" height="50px" />
		</b-navbar-brand>

		<template v-if="!this.disableToggle">
			<b-navbar-toggle target="navbar-toggle-collapse"> </b-navbar-toggle>

			<b-collapse id="navbar-toggle-collapse" is-nav>
				<slot></slot>
			</b-collapse>
		</template>
	</b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	props: ["disableToggle"],
	computed: {
		...mapGetters({
			appLogo: "getAppLogo",
		}),
	},
};
</script>
