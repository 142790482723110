<template>
  <div>
    <template v-if="adminToken">
      <!-- Admin Navbar -->
      <AdminNavbar />

      <!-- Content -->
      <b-container fluid class="mt-3">
        <Content />
      </b-container>
    </template>
    <AdminLogin v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Content from "../../functions/custom-components/Content.vue";
import AdminNavbar from "../../components/navbars/admin/AdminNavbar.vue";
import AdminLogin from "./AdminLogin.vue";
export default {
  components: { Content, AdminLogin, AdminNavbar },
  created() {
    // load masters
    this.loadMasters();
  },
  computed: {
    ...mapGetters({
      adminToken: "admin/getToken",
    }),
  },
  methods: {
    ...mapActions({
      loadMasters: "masters/loadMasters",
    }),
  },
};
</script>

<style></style>
