<template>
	<CommonNavbar>
		<b-navbar-nav>
			<!-- Global -->
			<b-nav-item-dropdown text="Global">
				<b-dropdown-item>
					<router-link :to="{ name: 'GlobalCategories' }" tag="span">
						Categories
					</router-link>
				</b-dropdown-item>
				<b-dropdown-item>
					<router-link :to="{ name: 'GlobalProducts' }" tag="span">
						Products
					</router-link>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>

		<!-- Right aligned nav items -->
		<b-navbar-nav class="ml-auto">
			<b-nav-item href="#" @click.prevent="logout">
				<b-icon-box-arrow-right />
				Logout
			</b-nav-item>
		</b-navbar-nav>
	</CommonNavbar>
</template>

<script>
import { mapActions } from "vuex";
import CommonNavbar from "../CommonNavbar";
export default {
	components: {
		CommonNavbar,
	},
	methods: {
		...mapActions({
			storeLogout: "admin/logout",
		}),
		logout() {
			this.storeLogout().then(() => {
				// this.$router.replace("/");
				console.log("Logout completed");
			});
		},
	},
};
</script>
