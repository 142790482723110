<template>
	<b-container fluid>
		<b-row class="mt-2" align-h="center">
			<b-col xl="4" lg="6" md="9">
				<b-card class="text-center">
					<!-- Baycho Logo -->
					<header class="pb-2">
						<b-img :src="appLogo" fluid />
					</header>

					<!-- Title -->
					<b-card-title class="text-primary">Admin Login</b-card-title>

					<!-- Form -->
					<b-form @submit.prevent="onSubmit" class="pt-2">
						<b-form-group :invalid-feedback="getErrorMessage(errors, 'username')" :state="getErrorState(errors, 'username')">
							<b-form-input :state="getErrorState(errors, 'username')" id="username" v-model="form.username" placeholder="Username" trim />
						</b-form-group>

						<form-password :invalid-feedback="getErrorMessage(errors, 'password') || getErrorMessage(errors, 'message')" :state="getErrorState(errors, 'password') || getErrorState(errors, 'message')" id="password" v-model="form.password" placeholder="Password"></form-password>

						<b-button type="submit" variant="primary" class="text-uppercase text-white" pill block>
							<span> <b-icon-arrow-right scale="0.7" /> Sign In </span>
						</b-button>
					</b-form>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { variables } from "@/debug";

export default {
	data() {
		return {
			form: {
				username: variables.mobile,
				password: variables.password,
			},
			errors: null,
		};
	},
	computed: {
		...mapGetters({
			appLogo: "getAppLogo",
		}),
	},
	methods: {
		...mapActions({
			saveToken: "admin/saveToken",
		}),
		onSubmit() {
			this.errors = null;
			if (this.form.username == process.env.VUE_APP_ADMIN_USERNAME && this.form.password == process.env.VUE_APP_ADMIN_PASSWORD) {
				this.saveToken("self_created_login_token");
			} else {
				this.errors = {
					username: "Bad Credentials",
					password: "Bad Credentials",
				};
			}
		},
	},
};
</script>

<style></style>
